import React, { useEffect, useState } from "react"
import EditModalRow from "./editModalRow"
import Modal from "./modal"
import { LendPriceOption, LendTimeOption } from "../../utils"
import DropDown from "./dropdown"

const EditModal = ({
  buttonMsg,
  title,
  exitModalCallback,
  transactionCallback,
  data,
}) => {
  //TODO: clean this up
  const [price, setPrice] = useState(25)
  const [priceType, setPriceType] = useState(
    Object.entries(LendPriceOption)[0][1]
  )
  const [time, setTime] = useState(7)
  const [timeType, setTimeType] = useState(Object.entries(LendTimeOption)[0][1])
  const [updateAllData, setUpdateAllData] = useState({
    priceType: LendPriceOption.SLP,
    price: 25,
    timeType: LendTimeOption.DAY,
    time: 7,
  })
  const [updateAxies, setUpdateAxies] = useState(new Map())

  useEffect(() => {
    validatePrice()
    validateTime()
  }, [priceType, timeType])

  const update = () => {
    transactionCallback(updateAxies)
  }

  const handleUpdatePrice = e => {
    e.preventDefault()
    let price = e.target.value
    setPrice(price)
  }

  const handleUpdateTime = e => {
    e.preventDefault()
    let time = e.target.value
    setTime(time)
  }

  const validatePrice = () => {
    if (priceType === LendPriceOption.SLP) {
      if (price < 1) {
        setPrice(1)
      } else if (price >= 100) {
        setPrice(99)
      }
    } else {
      if (price <= 0) {
        setPrice(1)
      }
    }
  }

  const validateTime = () => {
    if (time < 1) {
      setTime(1)
    }
  }

  const priceDropdownCallback = select => {
    let value = select[1]
    if (value === LendPriceOption.SLP) {
      setPriceType(LendPriceOption.SLP)
    } else {
      setPriceType(LendPriceOption.ETH)
    }
  }

  const timeDropdownCallback = select => {
    let value = select[1]
    if (value === LendTimeOption.DAY) {
      setTimeType(LendTimeOption.DAY)
    } else {
      setTimeType(LendTimeOption.WEEK)
    }
  }

  const updateAll = () => {
    let update = {
      priceType: priceType,
      price: price,
      timeType: timeType,
      time: time,
    }
    setUpdateAllData(update)
  }

  const handleUpdateCallback = (id, eth, slp, duration) => {
    let updateObj = {
      eth: eth,
      slp: slp,
      duration: duration,
    }
    let tmp = updateAxies
    tmp.set(id, updateObj)
    setUpdateAxies(tmp)
  }

  return (
    <Modal title={title} exitModalCallback={exitModalCallback}>
      <div className="edit-container">
        <div className="flex flex-row items-center justify-start">
          {/* Below taken from: https://tailwindui.com/components/application-ui/forms/input-groups */}
          <input
            type="number"
            min="1"
            name="price"
            id="price-master"
            className="price-input border border-axietree-accent bg-card-bg text-axietree-accent focus:outline-none"
            onChange={handleUpdatePrice}
            onBlur={validatePrice}
            value={price}
          />
          <DropDown
            options={LendPriceOption}
            startOption={0}
            dropdownCallback={priceDropdownCallback}
          />
          <div
            className={
              (priceType === LendPriceOption.SLP ? "invisible" : "visible") +
              " flex flex-row items-center flex-grow"
            }
          >
            <p className="mx-3 text-white">for</p>
            <input
              type="text"
              min="1"
              value={time}
              name="time"
              id="time-master"
              onChange={handleUpdateTime}
              onBlur={validateTime}
              className="price-input border border-axietree-accent bg-card-bg text-axietree-accent focus:outline-none"
            />
            <div className="flex-grow">
              <DropDown
                options={LendTimeOption}
                startOption={0}
                dropdownCallback={timeDropdownCallback}
              />
            </div>
          </div>
          <button
            onClick={() => updateAll()}
            className="update-all bg-axietree-accent text-background"
          >
            Update All
          </button>
        </div>
        <div className="h-full overflow-y-auto border-t border-card-stroke mt-5">
          {updateAllData &&
            data.map(d => {
              return (
                <EditModalRow
                  updateAllData={updateAllData}
                  handleUpdateCallback={handleUpdateCallback}
                  key={d.id}
                  data={d}
                />
              )
            })}
        </div>
        <button
          onClick={() => update()}
          className="edit-update-btn w-full bg-axietree-accent text-background mt-3"
        >
          {buttonMsg}
        </button>
      </div>
    </Modal>
  )
}

export default EditModal
