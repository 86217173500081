// Frameworks
import React from "react"
import { Router } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

// Layout Management
import PublicRoute from "./components/PublicRoute"
import PrivateRoute from "./components/PrivateRoute"
import Layout from "../components/layout"
import Header from "../components/header"
import Login from "../components/registration/login"

// Route Templates
import Main from "./main"

// Dynamic Application Wrapper
const App = props => {
  const siteData = useStaticQuery(graphql`
    query SiteDataQuery {
      site {
        siteMetadata {
          title
          logoUrl
        }
      }
    }
  `)

  // Primary App Layout + Router
  // Need header here to get metamask context
  return (
    <Layout>
      <Header />
      <Router className="flex flex-col flex-grow">
        <PublicRoute path="/app">
          <PrivateRoute path="/" component={Main} />
          <Login path="/login" />
        </PublicRoute>
      </Router>
    </Layout>
  )
}

export default App
