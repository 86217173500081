import React, { useState, useEffect } from "react"
import ReactPaginate from "react-paginate"
import { Link } from "gatsby"

import Card from "../grid/card"
import BorrowNav, { navChoices } from "./borrowNav"
import Filter from "../filter/filter"
import Account from "../account/account"

import { getMarketData, getTransactionUrl, isFilterDisplay } from "../../utils"
import EpochModal from "../modal/epochModal"

//Web3React Provider
import { useWeb3React } from "@web3-react/core"
import Web3 from "web3"
//API
import {
  fetchBorrowerAxies,
  borrow,
  payback,
  remove,
} from "../../services/contractsApi"
import {
  getAxies,
  getAllAxies,
  getAccountStatus,
  AccountStatus,
} from "../../services/axieTreeApi"
import ConfirmTransactionModal from "../modal/confirmTransactionModal"
import Loading from "../loading"

//TODO: add filter logic

const Borrow = () => {
  const context = useWeb3React()
  const { library, account } = context

  const [filter, setFilter] = useState()
  const [displayFilter, setDisplayFilter] = useState(false)

  const [showAccountModal, setShowAccountModal] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 1024) {
      setDisplayFilter(true)
    }
  }, [])

  const openAccountModal = () => {
    setShowAccountModal(true)
  }

  const closeAccountModal = () => {
    setShowAccountModal(false)
  }

  const filterCallback = curFilter => {
    let tmp = JSON.parse(JSON.stringify(curFilter))
    setFilter(tmp)
  }

  const closeFilter = () => {
    setDisplayFilter(false)
  }

  const showFilter = () => {
    setDisplayFilter(true)
  }

  const [showEpochModal, setShowEpochModal] = useState(false)
  const renderEpochModal = () => {
    let modalTitle = `Edit ${selectedReturnAxies.length} Axies`
    return (
      <EpochModal
        title={modalTitle}
        exitModalCallback={exitEpochModalCallback}
        transactionCallback={updateEpochCallback}
      />
    )
  }
  const exitEpochModalCallback = () => {
    setShowEpochModal(false)
  }

  const updateEpochCallback = isReturn => {
    let tmp = []
    for (var i = 0; i < selectedReturnAxies.length; i++) {
      tmp.push(isReturn)
    }

    remove(library, account, selectedReturnAxies, tmp)
      .then(response => {
        console.log("sucess update()")
        setShowSuccessTransaction(true)
        setSuccessTransactionUrl(
          getTransactionUrl(response.hash, response.networkId)
        )
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        exitEpochModalCallback()
      })
  }

  const submitEpochCallback = () => {
    setShowEpochModal(true)
  }

  const [curTab, setCurTab] = useState(navChoices.BORROWABLE)

  const [accountStatus, setAccountStatus] = useState()

  const [isLoading, setIsLoading] = useState(false)

  const [showSuccessTransaction, setShowSuccessTransaction] = useState(false)
  const [successTransactionUrl, setSuccessTransactionUrl] = useState()

  //Data for borrow (BORROWABLE) tab
  const [borrowList, setBorrowList] = useState()
  const [displayBorrowList, setDisplayBorrowList] = useState([])
  const [offsetBorrow, setOffsetBorrow] = useState(0)
  const [selectedBorrowAxies, setSelectedBorrowAxies] = useState([])
  //TODO: make this cleaner but this is only way to update child components for now...
  const [selectedBorrowAxiesLength, setSelectedBorrowAxiesLength] = useState(0)

  //Data for return (BORROWED) tab
  const [returnList, setReturnList] = useState()
  const [displayReturnList, setDisplayReturnList] = useState([])
  const [offsetReturn, setOffsetReturn] = useState(0)
  const [selectedReturnAxies, setSelectedReturnAxies] = useState([])
  const [selectedReturnAxiesLength, setSelectedReturnAxiesLength] = useState(0)

  useEffect(() => {
    if (account) {
      getAccountStatus(account).then(response => {
        setAccountStatus(response)
      })
    }
  }, [account])

  //TODO-API: handle borrow call here
  const handleBorrowSelected = (isChecked, id) => {
    let tmp = selectedBorrowAxies
    if (isChecked) {
      tmp.push(id)
    } else {
      let i = tmp.indexOf(id)
      if (i > -1) {
        tmp.splice(i, 1)
      }
    }

    setSelectedBorrowAxies(tmp)
    setSelectedBorrowAxiesLength(tmp.length)
  }

  //TODO-API: handle return call here
  const handleReturnSelected = (isChecked, id) => {
    let tmp = selectedReturnAxies
    if (isChecked) {
      tmp.push(id)
    } else {
      let i = tmp.indexOf(id)
      if (i > -1) {
        tmp.splice(i, 1)
      }
    }

    setSelectedReturnAxies(tmp)
    setSelectedReturnAxiesLength(tmp.length)
  }

  const borrowNavCallback = tab => {
    setCurTab(tab)
  }

  const successTransactionCloseCallback = () => {
    setShowSuccessTransaction(false)
    setSuccessTransactionUrl("")
  }

  const submitBorrowListCallback = (borrowList = selectedBorrowAxies) => {
    if (borrowList.length > 3) {
      alert("Heads up! You may only borrow up to 3 Axies at a time")
    } else {
      borrow(library, account, borrowList)
        .then(response => {
          console.log("success borrow()")
          setShowSuccessTransaction(true)
          setSuccessTransactionUrl(
            getTransactionUrl(response.hash, response.networkId)
          )
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const submitReturnListCallback = () => {
    payback(library, account, selectedReturnAxies)
      .then(response => {
        console.log("success payback()")
        setShowSuccessTransaction(true)
        setSuccessTransactionUrl(
          getTransactionUrl(response.hash, response.networkId)
        )
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (borrowList || returnList) {
      console.log(borrowList)
      return
    }
    fetchBorrowerAxies(library, account, setBorrowList, setReturnList)
  }, [borrowList, returnList])

  const handleScrollEnd = e => {
    if (!isLoading) {
      let element = e.target
      let isBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight
      switch (curTab) {
        case navChoices.BORROWABLE:
          if (isBottom) setOffsetBorrow(offsetBorrow + 10)
          break
        case navChoices.BORROWING:
          if (isBottom) setOffsetReturn(offsetReturn + 10)
          break
      }
    }
  }

  //Borrow logic to get axie data
  useEffect(() => {
    if (borrowList) {
      let axieIds = []
      for (let i = 0; i < borrowList.length; i++) {
        if (!borrowList[i]) {
          break
        }
        axieIds.push(borrowList[i]["id"])
      }
      getAllAxies(axieIds)
        .then(response => {
          console.log("all gathered!")
          let tmp = displayBorrowList.concat(response)
          setDisplayBorrowList(tmp)
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [borrowList])

  //Return logic to get axie data
  useEffect(() => {
    if (returnList) {
      let axieIds = []
      for (let i = 0; i < returnList.length; i++) {
        if (!returnList[i]) {
          break
        }
        axieIds.push(returnList[i]["id"])
      }
      getAllAxies(axieIds)
        .then(response => {
          console.log("all gathered!")
          let tmp = displayReturnList.concat(response)
          setDisplayReturnList(tmp)
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [returnList])

  //If registered, show full borrowable and borrowed axies with navigation
  if (account && borrowList && returnList) {
    return (
      <div className="flex flex-row flex-grow">
        {showAccountModal && (
          <div
            onClick={closeAccountModal}
            className="absolute flex items-center top-0 right-0 w-screen h-screen bg-background bg-opacity-80 overscroll-contain z-50"
          >
            <div
              onClick={e => {
                //stop clicks getting to the overlay
                e.stopPropagation()
              }}
              className="mx-auto"
            >
              <Account closeModalCallback={closeAccountModal} />
            </div>
          </div>
        )}
        {showEpochModal && renderEpochModal()}
        <div
          className={
            (displayFilter ? "border-r border-card-stroke" : "") +
            " flex flex-col"
          }
        >
          <div
            className={(displayFilter ? "" : "invisible") + " flex-grow h-0"}
          >
            <Filter
              filterCallback={filterCallback}
              toggleFilterCallback={closeFilter}
            />
          </div>
        </div>
        <div className="flex flex-col flex-grow">
          <BorrowNav
            curTab={curTab}
            borrowNavCallback={borrowNavCallback}
            submitBorrowListCallback={submitBorrowListCallback}
            submitReturnListCallback={submitEpochCallback}
            totalBorrowable={borrowList.length}
            totalReturnable={returnList.length}
            borrowSelectedLength={selectedBorrowAxiesLength}
            returnSelectedLength={selectedReturnAxiesLength}
          />
          <div className="my-16">
            <button
              className="md:hidden ml-24 mb-8 px-16 py-10 bg-background border border-axietree-accent rounded-md text-axietree-accent"
              onClick={() => setDisplayFilter(true)}
            >
              <p>Filter (0)</p>
            </button>
            <h2 className="text-axietree-accent text-left ml-24">
              Borrowers may only borrow 3 Axies at a time
            </h2>
          </div>
          <div
            onScroll={handleScrollEnd}
            className="flex-grow h-0 md:overflow-y-auto axie-grid grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 xxxl:grid-cols-5"
          >
            {/* Borrowable axies */}
            {curTab === navChoices.BORROWABLE &&
              borrowList &&
              displayBorrowList.map(d => {
                let index = selectedBorrowAxies.indexOf(d)
                let isChecked = false
                if (index > -1) isChecked = true

                if (d.stage < 3) {
                  return
                }

                return (
                  <div
                    className={
                      (isFilterDisplay(filter, d, borrowList) ? "" : "hidden") +
                      " grid-card"
                    }
                    key={d.id + "b"}
                  >
                    <Card
                      isChecked={isChecked}
                      axieSelectCallback={handleBorrowSelected}
                      data={d}
                      marketData={getMarketData(d, borrowList)}
                      key={d.id + "b-card"}
                    >
                      {accountStatus === AccountStatus.REGISTERED ? (
                        <button
                          className="card-button"
                          onClick={() => submitBorrowListCallback([d.id])}
                        >
                          Borrow
                        </button>
                      ) : (
                        // <Link className="w-full" to="/registration">
                        //   <button className="card-button">
                        //     Register to Borrow
                        //   </button>
                        // </Link>
                        <button
                          className="card-button"
                          onClick={openAccountModal}
                        >
                          Register to Borrow
                        </button>
                      )}
                    </Card>
                  </div>
                )
              })}
            {/* Currently borrowed axies */}
            {curTab === navChoices.BORROWING &&
              returnList &&
              displayReturnList.map(d => {
                let index = selectedReturnAxies.indexOf(d)
                let isChecked = false
                if (index > -1) isChecked = true

                if (d.stage < 3) {
                  return
                }

                return (
                  <div
                    className={
                      (isFilterDisplay(filter, d, returnList) ? "" : "hidden") +
                      " grid-card"
                    }
                    key={d.id + "r"}
                  >
                    <Card
                      isChecked={isChecked}
                      axieSelectCallback={handleReturnSelected}
                      data={d}
                      marketData={getMarketData(d, returnList)}
                      key={d.id + "r-card"}
                    ></Card>
                  </div>
                )
              })}
            {isLoading && (
              <div className="mx-auto">
                <h1 className="text-axietree-accent">Fetching...</h1>
                <div className="mx-auto">
                  <Loading />
                </div>
              </div>
            )}
            {showSuccessTransaction && (
              <div className="absolute top-5 right-5">
                <ConfirmTransactionModal
                  link={successTransactionUrl}
                  closeModalCallback={successTransactionCloseCallback}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  } else {
    // TODO: add loading spinner?
    return (
      <div className="mx-auto my-auto items-center">
        <h1 className="text-axietree-accent">Fetching...</h1>
        <div className="mx-auto">
          <Loading />
        </div>
      </div>
    )
  }
}

export default Borrow
