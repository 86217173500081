import React from "react"

import healthIcon from "../../images/icons/card/health.svg"
import speedIcon from "../../images/icons/card/speed.svg"
import skillIcon from "../../images/icons/card/skill.svg"
import moraleIcon from "../../images/icons/card/morale.svg"

const AxieStats = ({ stats, id }) => {
  if (!stats) {
    return (
      <div className="flex flex-row justify-between w-full border-t border-b border-card-stroke py-16 px-32">
        <div className="flex items-center text-secondary-text">
          <img src={healthIcon} alt="health" />
          <p className="ml-8">N/A</p>
        </div>
        <div className="flex items-center text-secondary-text">
          <img src={speedIcon} alt="speed" />
          <p className="ml-8">N/A</p>
        </div>
        <div className="flex items-center text-secondary-text">
          <img src={skillIcon} alt="skill" />
          <p className="ml-8">N/A</p>
        </div>
        <div className="flex items-center text-secondary-text">
          <img src={moraleIcon} alt="morale" />
          <p className="ml-8">N/A</p>
        </div>
        <a
          href={`https://marketplace.axieinfinity.com/axie/${id}`}
          target="_blank"
        >
          <svg
            className="fill-current text-secondary-text"
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            viewBox="0 0 24 24"
            width="16"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
          </svg>
        </a>
      </div>
    )
  } else {
    const { hp, speed, skill, morale } = stats
    return (
      <div className="flex flex-row justify-between w-full border-t border-b border-card-stroke py-16 px-32">
        <div className="flex items-center text-secondary-text">
          <img src={healthIcon} alt="health" />
          <p className="ml-8">{hp ? hp : "N/A"}</p>
        </div>
        <div className="flex items-center text-secondary-text">
          <img src={speedIcon} alt="speed" />
          <p className="ml-8">{speed ? speed : "N/A"}</p>
        </div>
        <div className="flex items-center text-secondary-text">
          <img src={skillIcon} alt="skill" />
          <p className="ml-8">{skill ? skill : "N/A"}</p>
        </div>
        <div className="flex items-center text-secondary-text">
          <img src={moraleIcon} alt="morale" />
          <p className="ml-8">{morale ? morale : "N/A"}</p>
        </div>
        <a
          href={`https://marketplace.axieinfinity.com/axie/${id}`}
          target="_blank"
        >
          <svg
            className="fill-current text-secondary-text"
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            viewBox="0 0 24 24"
            width="16"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
          </svg>
        </a>
      </div>
    )
  }
}

export default AxieStats
