//Taken from https://tailwindui.com/components/application-ui/elements/dropdowns
import React, { useState, useEffect } from "react"

const DropDown = ({ dropdownCallback, options, startOption = 0 }) => {
  const [showSort, setShowSort] = useState(false)
  const [select, setSelect] = useState(Object.entries(options)[startOption])
  const toggleSort = () => {
    setShowSort(!showSort)
  }

  useEffect(() => {
    if (startOption) {
      setSelect(Object.entries(options)[startOption])
    }
  }, [startOption])

  const updateSort = select => {
    setSelect(select)
    setShowSort(false)
    dropdownCallback(select)
  }

  return (
    //TODO: clean up the styles here
    <div className="sort relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-between border border-axietree-accent hover:bg-card-bg focus:outline-none focus:ring-2 focus:ring-card-stroke"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={toggleSort}
        >
          <h3>{select[1]}</h3>
          <svg
            className="-mr-1 ml-2 h-5 w-5 text-left"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {showSort && (
        <div className="options z-50 bg-background text-axietree-accent origin-top-right absolute right-0">
          <div
            className=""
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {Object.entries(options).map(e => {
              return (
                <a
                  key={e[0]}
                  onClick={() => updateSort(e)}
                  href="#"
                  className="block px-4 py-2 text-sm hover:bg-card-bg"
                  role="menuitem"
                >
                  {e[1]}
                </a>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default DropDown
