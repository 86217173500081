import React from "react"

const ConfirmTransactionModal = ({ link, closeModalCallback }) => {
  return (
    <div className="animate-pulse confirm-modal bg-axietree-accent text-background flex flex-col justify-between">
      <div className="flex flex-row items-center justify-between">
        <h3>Your transaction is pending</h3>
        <button onClick={() => closeModalCallback()}>
          <svg
            className="fill-current text-background"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              className="heroicon-ui"
              d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
            />
          </svg>
        </button>
      </div>
      <a
        className="bg-background text-axietree-accent"
        href={link}
        target="_blank"
      >
        View on Etherscan
      </a>
    </div>
  )
}

export default ConfirmTransactionModal
