import React from "react"

import { classColor } from "../../utils"
import Checkbox from "../checkbox"

import {
  parsePrice,
  parseDuration,
  getMysticLevel,
  classIcon,
} from "../../utils"
import AxieStats from "./AxieStats"

const Card = ({
  data,
  marketData,
  axieSelectCallback,
  isChecked,
  earnings,
  children,
}) => {
  const axieData = data
  const axieClass = axieData.class
  const mysticLevel = getMysticLevel(axieData)

  // console.log(axieData)

  const colorCategory = classColor[axieClass]
    ? classColor[axieClass]
    : "secondary-text"

  //TODO: update text size according to wireframe

  const onCheckboxAxie = (event, id) => {
    axieSelectCallback(event.target.checked, id)
  }

  //Get class icon
  let displayClassIcon
  switch (axieData.class) {
    case "beast":
      displayClassIcon = classIcon.beast
      break
    case "aquatic":
      displayClassIcon = classIcon.aquatic
      break
    case "plant":
      displayClassIcon = classIcon.plant
      break
    case "bird":
      displayClassIcon = classIcon.bird
      break
    case "bug":
      displayClassIcon = classIcon.bug
      break
    case "reptile":
      displayClassIcon = classIcon.reptile
      break
    case "hidden_1":
      displayClassIcon = classIcon.mech
      break
    case "hidden_2":
      displayClassIcon = classIcon.dawn
      break
    case "hidden_3":
      displayClassIcon = classIcon.dusk
      break
  }

  // console.log(displayClassIcon)

  return (
    <div className="card">
      <div className="card-info">
        <div className="">
          <img className="w-full" src={axieData.image} alt="AxieIMG" />
        </div>
        <div className="stats">
          <div className="flex flex-row justify-between w-full">
            <div className="inline-flex">
              <a
                className={
                  "bg-" +
                  colorCategory +
                  " axie-number cursor-pointer hover:underline hover:opacity-80"
                }
                href={`https://marketplace.axieinfinity.com/axie/${axieData.id}`}
                target="_blank"
              >
                #{axieData.id}
              </a>
              {mysticLevel > 0 && (
                <div className="bg-axie-type-mystic axie-number">
                  {mysticLevel} MYSTIC
                </div>
              )}
            </div>
            <Checkbox
              isChecked={isChecked}
              onClick={e => onCheckboxAxie(e, axieData)}
            />
          </div>
          {/* TODO: add class icon */}
          <div className="flex flex-row w-full">
            <img className="mr-8" src={displayClassIcon} alt="breed" />
            <div className="card-info-name">{axieData.name}</div>
          </div>
          {marketData.parseDuration && (
            <div className="card-info-loan">{parseDuration(marketData)}</div>
          )}
          {earnings && (
            <div className="card-info-loan">Pending earnings: {earnings}</div>
          )}
          <div className="card-info-loan">
            Breed Count: {axieData.breedCount}/7
          </div>
          {(marketData.eth || marketData.slp) && (
            <div className="card-info-lend-rate">{parsePrice(marketData)}</div>
          )}
        </div>
      </div>
      <AxieStats stats={data.stats} id={axieData.id} />
      <div className="card-parts">
        {axieData.parts &&
          axieData.parts.map(d => (
            <div key={d.id} className="part">
              <img src={d.url} alt="" />
              <p>{d.name}</p>
            </div>
          ))}
      </div>
      {children}
    </div>
  )
}

export default Card
