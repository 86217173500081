import React, { useState } from "react"
import { useWeb3React } from "@web3-react/core"

import {
  hasBorrowerAccess,
  getAccountForEmail,
  registerUser,
} from "../../services/axieTreeApi"

const Account = ({ closeModalCallback }) => {
  const context = useWeb3React()
  const { library, account } = context

  const [inputEmail, setInputEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)
  const [isRegistered, setIsRegistered] = useState(false)

  const handleChange = event => {
    let tmpEmail = event.target.value
    setInputEmail(tmpEmail)

    setValidEmail(isEmailValid(tmpEmail))
  }

  const isEmailValid = email => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const register = event => {
    // event.preventDefault()

    ;(async () => {
      let response = await hasBorrowerAccess(inputEmail)
      setIsRegistered(true)
    })()
  }

  return (
    <div
      className="account bg-card-bg text-white flex flex-col justify-between px-24 pt-24 pb-32 rounded"
      style={{ maxWidth: "343px" }}
    >
      <div className="flex flex-row justify-between pb-16 items-center">
        <h1>AxieTree Account</h1>
        <button onClick={closeModalCallback}>
          <svg
            className="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="32"
            height="32"
          >
            <path
              className="heroicon-ui"
              d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-col py-16 border-b border-t border-card-stroke">
        <h2 className="text-axie-type-beast mb-8">
          Borrower account creation is temporarily halted
        </h2>
        <h3>Please stay tuned in our Discord for further updates</h3>
      </div>
      <div className="my-16">
        <h3>Register for email updates to stay in the loop with AxieTree</h3>
      </div>
      <div className="w-full mb-16">
        <input
          className="w-full rounded focus:outline-none focus:ring-2 focus:ring-card-stroke focus:border-transparent border-axietree-accent bg-background text-axietree-accent placeholder-axietree-accent focus:placeholder-transparent"
          type="email"
          placeholder="Enter email address"
          onChange={handleChange}
          value={inputEmail}
        ></input>
      </div>
      <div className="w-full">
        <button
          className={
            (validEmail ? "opacity-100" : "opacity-20") +
            " registration-item bg-axietree-accent text-background focus:outline-none active:bg-card-stroke w-full"
          }
          onClick={() => register()}
          disabled={!validEmail || isRegistered}
        >
          {!isRegistered ? "Register" : "Thanks for Registering!"}
        </button>
      </div>
    </div>
  )
}

export default Account
