import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { observer } from "mobx-react-lite"

//Web3React Provider
import { useWeb3React } from "@web3-react/core"

const PublicRoute = observer(props => {
  const context = useWeb3React()
  const { account } = context
  const { location } = props

  useEffect(() => {
    //TODO: add wallet stuff here?
    if (account && location.pathname === `/app/login`) {
      // If the user is logged in, redirect to the main page.
      navigate(`/app/`)
      //TODO: need this?
    }
  }, [account, location])
  return <div className="flex flex-col flex-grow">{props.children}</div>
})

export default PublicRoute
