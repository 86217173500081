import React, { useState } from "react"
import Modal from "./modal"

const EpochModal = ({ title, exitModalCallback, transactionCallback }) => {
  const [selectedReturn, setSelectedReturn] = useState(true)

  const handleToggle = isReturn => {
    setSelectedReturn(isReturn)
  }

  const update = () => {
    transactionCallback(selectedReturn)
  }

  return (
    <Modal title={title} exitModalCallback={exitModalCallback}>
      <div className="flex flex-col text-axietree-accent text-left">
        <p className="text-left mx-1 my-2">At the end of Epoch:</p>
        <div className="flex flex-row w-full my-2">
          <button
            onClick={() => handleToggle(true)}
            className={
              (!selectedReturn ? "" : "bg-axietree-accent text-background") +
              " expire-content-btn mx-1"
            }
          >
            End Rental
          </button>
          <button
            onClick={() => handleToggle(false)}
            className={
              (selectedReturn ? "" : "bg-axietree-accent text-background") +
              " expire-content-btn mx-1"
            }
          >
            Keep Borrowing
          </button>
        </div>
        <div>
          <button
            onClick={() => update()}
            className="edit-update-btn w-full bg-axietree-accent text-background mt-3"
          >
            Update Axies
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default EpochModal
