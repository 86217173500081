import React from "react"
import Modal from "./modal"

const RemoveModal = ({ title, exitModalCallback, transactionCallback }) => {
  return (
    <Modal title={title} exitModalCallback={exitModalCallback}>
      <div className="flex flex-col text-axietree-accent text-left">
        <p className="text-left mx-1 my-2">
          You’re about to remove your posted Axies from the Marketplace. Please
          make sure you’d like to continue.
        </p>
        <div>
          <div className="flex flex-row w-full my-2">
            <button
              onClick={() => exitModalCallback()}
              className="expire-content-btn mx-1"
            >
              Cancel
            </button>
            <button
              onClick={() => transactionCallback()}
              className="bg-axietree-accent text-background expire-content-btn mx-1"
            >
              Yes, remove my posted Axies
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RemoveModal
