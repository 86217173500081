import React, { useState, useEffect } from "react"
import { LendPriceOption, LendTimeOption } from "../../utils"
import DropDown from "./dropdown"

import { classColor } from "../../utils"

const EditModalRow = ({ data, updateAllData, handleUpdateCallback }) => {
  const axieData = data
  const axieClass = axieData.class

  const colorCategory = classColor[axieClass]
    ? classColor[axieClass]
    : "secondary-text"

  //TODO: clean this up
  const [price, setPrice] = useState(updateAllData.price)
  const [priceType, setPriceType] = useState(updateAllData.priceType)
  const [time, setTime] = useState(updateAllData.time)
  const [timeType, setTimeType] = useState(updateAllData.timeType)

  useEffect(() => {
    validatePrice()
    validateTime()
  }, [priceType, timeType])

  useEffect(() => {
    sendBackSelectedData()
  })

  useEffect(() => {
    setPrice(updateAllData.price)
    setPriceType(updateAllData.priceType)
    setTime(updateAllData.time)
    setTimeType(updateAllData.timeType)
  }, [updateAllData])

  const sendBackSelectedData = () => {
    let eth = 0
    let slp = 0
    if (priceType === LendPriceOption.SLP) {
      slp = price
    } else {
      eth = price
    }

    let duration
    if (timeType === LendTimeOption.DAY) {
      duration = time * 24 * 60 * 60 //86400 seconds in 1 day
    } else {
      duration = time * 7 * 24 * 60 * 60 //604800 seconds in 1 week
    }
    handleUpdateCallback(data.id, eth, slp, duration)
  }

  const handleUpdatePrice = e => {
    e.preventDefault()
    let price = e.target.value
    setPrice(price)
  }

  const handleUpdateTime = e => {
    e.preventDefault()
    let time = e.target.value
    setTime(time)
  }

  const validatePrice = () => {
    if (priceType === LendPriceOption.SLP) {
      if (price < 1) {
        setPrice(1)
      } else if (price >= 100) {
        setPrice(99)
      }
    } else {
      if (price <= 0) {
        setPrice(1)
      }
    }
  }

  const validateTime = () => {
    if (time < 1) {
      setTime(1)
    }
  }

  const priceDropdownCallback = select => {
    let value = select[1]
    if (value === LendPriceOption.SLP) {
      setPriceType(LendPriceOption.SLP)
    } else {
      setPriceType(LendPriceOption.ETH)
    }
  }

  const timeDropdownCallback = select => {
    let value = select[1]
    if (value === LendTimeOption.DAY) {
      setTimeType(LendTimeOption.DAY)
    } else {
      setTimeType(LendTimeOption.WEEK)
    }
  }

  return (
    <div className="edit-modal-row flex flex-row">
      <img className="h-full w-auto" src={axieData.image} alt="Axie IMG" />
      <div className="flex flex-col justify-evenly w-full">
        <div className="flex flex-row justify-between w-full">
          <div className={"bg-" + colorCategory + " axie-number text-white"}>
            #{axieData.id}
          </div>
          {/* <button onClick={() => handleRemoveAxieCallback(axieData)}>
            <svg
              className="fill-current text-axietree-accent"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                className="heroicon-ui"
                d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
              />
            </svg>
          </button> */}
        </div>
        {/* TODO: add class icon */}
        <div className="card-info-name text-white">{axieData.name}</div>
        <div className="flex flex-row items-center justify-start">
          {/* Below taken from: https://tailwindui.com/components/application-ui/forms/input-groups */}
          <input
            type="number"
            min="1"
            name="price"
            id={"price" + data.id}
            className="price-input border border-axietree-accent bg-card-bg text-axietree-accent focus:outline-none"
            onChange={handleUpdatePrice}
            onBlur={validatePrice}
            value={price}
          />
          <DropDown
            options={LendPriceOption}
            startOption={priceType === LendPriceOption.SLP ? 0 : 1}
            dropdownCallback={priceDropdownCallback}
          />
          <div
            className={
              (priceType === LendPriceOption.SLP ? "invisible" : "visible") +
              " flex flex-row items-center flex-grow"
            }
          >
            <p className="mx-3 text-white">for</p>
            <input
              type="text"
              min="1"
              value={time}
              name="time"
              id={"time" + data.id}
              onChange={handleUpdateTime}
              onBlur={validateTime}
              className="price-input border border-axietree-accent bg-card-bg text-axietree-accent focus:outline-none"
            />
            <div className="flex-grow">
              <DropDown
                options={LendTimeOption}
                startOption={timeType === LendTimeOption.DAY ? 0 : 1}
                dropdownCallback={timeDropdownCallback}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditModalRow
