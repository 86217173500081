import React, { useState } from "react"
import Modal from "./modal"

const ExpireModal = ({ title, exitModalCallback, transactionCallback }) => {
  const [selectedWallet, setSelectedWallet] = useState(false)

  const handleToggle = isWallet => {
    setSelectedWallet(isWallet)
  }

  const update = () => {
    transactionCallback(selectedWallet)
  }

  return (
    <Modal title={title} exitModalCallback={exitModalCallback}>
      <div className="flex flex-col text-axietree-accent text-left">
        <p className="text-left mx-1 my-2">When loan expires return to:</p>
        <div className="flex flex-row w-full my-2">
          <button
            onClick={() => handleToggle(false)}
            className={
              (!selectedWallet ? "bg-axietree-accent text-background" : "") +
              " expire-content-btn mx-1"
            }
          >
            Marketplace
          </button>
          <button
            onClick={() => handleToggle(true)}
            className={
              (selectedWallet ? "bg-axietree-accent text-background" : "") +
              " expire-content-btn mx-1"
            }
          >
            Inventory
          </button>
        </div>
        <div>
          <button
            onClick={() => update()}
            className="edit-update-btn w-full bg-axietree-accent text-background mt-3"
          >
            Update Axies
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ExpireModal
