import React, { useEffect, useState } from "react"

import Sort from "./sort"

//API
import { getEarnings } from "../../services/axieTreeApi"

const MainNav = ({ handleChangeTabCallback, toggleFilterCallback }) => {
  const [earnings, setEarnings] = useState()
  const [isCurrentBorrowTab, setIsCurrentBorrowTab] = useState(true)

  useEffect(() => {
    // setEarnings(getEarnings())
  }, [])

  const handleChangeTab = () => {
    handleChangeTabCallback(isCurrentBorrowTab)
    setIsCurrentBorrowTab(!isCurrentBorrowTab)
  }

  const toggleFilter = () => {
    toggleFilterCallback()
  }

  const sortCallback = () => {}

  return (
    <div className="main-nav">
      <div className="borrow-lend">
        <button
          className={
            (isCurrentBorrowTab
              ? "bg-axietree-accent text-background"
              : "text-axietree-accent") + " main-nav-btn-borrow"
          }
          onClick={handleChangeTab}
          disabled={isCurrentBorrowTab}
        >
          <h2>Borrow</h2>
        </button>
        <button
          className={
            (!isCurrentBorrowTab
              ? "bg-axietree-accent text-background"
              : "text-axietree-accent") + " main-nav-btn-lend"
          }
          onClick={handleChangeTab}
          disabled={!isCurrentBorrowTab}
        >
          <h2>Lend</h2>
        </button>
      </div>
      {/* <Sort /> */}
    </div>
  )
}

export default MainNav
