import React, { useState, useEffect } from "react"

import List from "./list"
import LendNav, { navChoices } from "./lendNav"
import Card from "../grid/card"
import ExpireModal from "../modal/expireModal"
import EditModal from "../modal/editModal"
import RemoveModal from "../modal/removeModal"
import Loading from "../loading"
import ConfirmTransactionModal from "../modal/confirmTransactionModal"
import { hasLenderAccess, getAllAxies } from "../../services/axieTreeApi"
import axios from "axios"
import axiosRetry from "axios-retry"

import Filter from "../../components/filter/filter"

import { getMarketData, getTransactionUrl, isFilterDisplay } from "../../utils"
import { approved, approve, custodians } from "../../services/contractsApi"

import { useInterval } from "../../hooks/useInterval"

//API
import {
  fetchLenderAxies,
  lend,
  update,
  withdraw,
} from "../../services/contractsApi"

//Web3React Provider
import { useWeb3React } from "@web3-react/core"

axiosRetry(axios, { retries: 50, retryDelay: axiosRetry.exponentialDelay })

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const Lend = ({}) => {
  const context = useWeb3React()
  const { library, account } = context

  const [pendingSlp, setPendingSlp] = useState({})
  const [filter, setFilter] = useState()
  const [displayFilter, setDisplayFilter] = useState()

  useEffect(() => {
    if (window.innerWidth > 1048) {
      setDisplayFilter(true)
    }
  }, [])

  const filterCallback = curFilter => {
    let tmp = JSON.parse(JSON.stringify(curFilter))
    setFilter(tmp)
  }

  const closeFilter = () => {
    setDisplayFilter(false)
  }

  const showFilter = () => {
    setDisplayFilter(true)
  }

  const [showSuccessTransaction, setShowSuccessTransaction] = useState(false)
  const [successTransactionUrl, setSuccessTransactionUrl] = useState()
  const successTransactionCloseCallback = () => {
    setShowSuccessTransaction(false)
    setSuccessTransactionUrl("")
  }

  const [isApproved, setIsApproved] = useState(false)

  const [curTab, setCurTab] = useState(navChoices.LENDING)

  const [isLoading, setIsLoading] = useState(false)

  const [selectedEditLength, setSelectedEditLength] = useState(0)
  //This is the list that is sent before user confirms a transaction
  const [transactionAxieList, setTransactionAxieList] = useState([])

  //Data for lending (LENDING) tab
  const [lendingData, setLendingData] = useState()
  const [displayListLending, setDisplayListLending] = useState([])
  const [offsetLending, setOffsetLending] = useState(0)
  const [selectedLendingAxies, setSelectedLendingAxies] = useState([])

  //Data for posted (POSTED) tab
  const [postedData, setPostedData] = useState()
  const [displayListPosted, setDisplayListPosted] = useState([])
  const [offsetPosted, setOffsetPosted] = useState(0)
  const [selectedPostedAxies, setSelectedPostedAxies] = useState([])

  //Data for lendable (LENDABLE) tab
  const [lendableData, setLendableData] = useState()
  const [displayListLendable, setDisplayListLendable] = useState([])
  const [offsetLendable, setOffsetLendable] = useState(0)
  const [selectedLendableAxies, setSelectedLendableAxies] = useState([])
  //TODO: make this cleaner but this is only way to update child components for now...
  const [
    selectedLendableAxiesLength,
    setSelectedLendableAxiesLength,
  ] = useState(0)

  useInterval(async () => {
    if (!isApproved) {
      approved(library, account)
        .then(response => {
          if (response) {
            setIsApproved(true)
            console.log("user has approved lending")
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, 500)

  useEffect(() => {
    approved(library, account)
      .then(response => {
        if (response) {
          setIsApproved(true)
          console.log("user has approved lending")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    let run = async () => {
      if (lendingData) {
        let axieIDs = []
        for (let i = 0; i < lendingData.length; i++) {
          if (!lendingData[i]) {
            break
          }
          axieIDs.push(lendingData[i]["id"])
        }
        let cs = await custodians(library, account, axieIDs)
        let responses = []
        for (let c of cs) {
          responses.push(
            await axios.get(
              `https://lunacia.skymavis.com/game-api/clients/${c}/items/1`
            )
          )
          await sleep(1000)
        }
        let pendingMap = {}
        for (let i = 0; i < cs.length; i++) {
          let id = axieIDs[i]
          let response = responses[i]
          let pending =
            ((response.data.total - response.data.blockchain_related.balance) /
              3) *
            (lendingData[i]["slp"] / 100)
          console.log(pending)
          pendingMap[id] = `${pending} SLP`
        }
        setPendingSlp(pendingMap)
      }
    }
    run()
  }, [lendingData])

  const [showEditModal, setShowEditModal] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)

  const handleSelected = (isChecked, id) => {
    //TODO: clean up whatever this is...
    let tmp
    switch (curTab) {
      case navChoices.LENDING:
        tmp = selectedLendingAxies
        break
      case navChoices.POSTED:
        tmp = selectedPostedAxies
        break
      case navChoices.LENDABLE:
        tmp = selectedLendableAxies
        break
    }

    if (isChecked) {
      tmp.push(id)
    } else {
      let i = tmp.indexOf(id)
      if (i > -1) {
        tmp.splice(i, 1)
      }
    }

    switch (curTab) {
      case navChoices.LENDING:
        setSelectedLendingAxies(tmp)
        setSelectedEditLength(tmp.length)
        break
      case navChoices.POSTED:
        setSelectedPostedAxies(tmp)
        setSelectedEditLength(tmp.length)
        break
      case navChoices.LENDABLE:
        setSelectedLendableAxies(tmp)
        setSelectedEditLength(tmp.length)
        setSelectedLendableAxiesLength(tmp.length)
        break
    }
  }

  const lendNavCallback = tab => {
    setCurTab(tab)

    let tmp
    switch (tab) {
      case navChoices.LENDING:
        tmp = selectedLendingAxies
        break
      case navChoices.POSTED:
        tmp = selectedPostedAxies
        break
      case navChoices.LENDABLE:
        tmp = selectedLendableAxies
        break
    }

    setSelectedEditLength(tmp.length)
  }

  const submitEditListCallback = () => {
    let tmp
    switch (curTab) {
      case navChoices.LENDING:
        tmp = selectedLendingAxies
        break
      case navChoices.POSTED:
        tmp = selectedPostedAxies
        break
      case navChoices.LENDABLE:
        tmp = selectedLendableAxies
        break
    }

    setTransactionAxieList(tmp)
    setShowEditModal(true)
  }

  const submitEditAllCallback = () => {
    let tmp
    switch (curTab) {
      case navChoices.LENDING:
        tmp = lendingData
        break
      case navChoices.POSTED:
        tmp = postedData
        break
      case navChoices.LENDABLE:
        tmp = lendableData
        break
    }

    setTransactionAxieList(tmp)
    setShowEditModal(true)
  }

  const submitRemoveAllCallback = () => {
    let tmp = postedData
    setTransactionAxieList(tmp)
    setShowRemoveModal(true)
  }

  const submitRemoveSelectedCallback = () => {
    let tmp = selectedPostedAxies
    setTransactionAxieList(selectedPostedAxies)
    setShowRemoveModal(true)
  }

  useEffect(() => {
    if (lendingData || postedData || lendableData) {
      return
    }
    fetchLenderAxies(
      library,
      account,
      setLendingData,
      setPostedData,
      setLendableData
    )
  }, [lendingData, postedData, lendableData])

  const handleScrollEnd = e => {
    if (!isLoading) {
      let element = e.target
      let isBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight
      switch (curTab) {
        case navChoices.LENDING:
          if (isBottom) setOffsetLending(offsetLending + 10)
          break
        case navChoices.POSTED:
          if (isBottom) setOffsetPosted(offsetPosted + 10)
          break
        case navChoices.LENDABLE:
          if (isBottom) setOffsetLendable(offsetLendable + 10)
          break
      }
    }
  }

  //Lending logic to get axie data
  useEffect(() => {
    if (lendingData) {
      let axieIDs = []
      for (let i = 0; i < lendingData.length; i++) {
        if (!lendingData[i]) {
          break
        }
        axieIDs.push(lendingData[i]["id"])
      }
      getAllAxies(axieIDs)
        .then(response => {
          let tmp = displayListLending.concat(response)
          setDisplayListLending(tmp)
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [lendingData])

  //Posted logic to get axie data
  useEffect(() => {
    if (postedData) {
      let axieIDs = []
      for (let i = 0; i < postedData.length; i++) {
        if (!postedData[i]) {
          break
        }
        axieIDs.push(postedData[i]["id"])
      }
      getAllAxies(axieIDs)
        .then(response => {
          let tmp = displayListPosted.concat(response)
          setDisplayListPosted(tmp)
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [postedData])

  //Lendable logic to get axie data
  useEffect(() => {
    if (lendableData) {
      let axieIDs = []
      for (let i = 0; i < lendableData.length; i++) {
        if (!lendableData[i]) {
          break
        }
        axieIDs.push(lendableData[i]["id"])
      }
      getAllAxies(axieIDs)
        .then(response => {
          let tmp = displayListLendable.concat(response)
          setDisplayListLendable(tmp)
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [lendableData])

  const exitModalCallback = () => {
    setShowEditModal(false)
  }

  const exitRemoveModalCallback = () => {
    setShowRemoveModal(false)
  }

  const submitLendListCallback = () => {
    let tmp = selectedLendableAxies

    setTransactionAxieList(tmp)
    setShowEditModal(true)
  }

  const submitLendAllCallback = () => {
    console.log("lend all")
    let tmp = lendingData
    setTransactionAxieList(tmp)
    setShowEditModal(true)
  }

  //TODO-API: add call here to update lending expiration
  const updatedExpirationCallback = isReturnWallet => {
    withdraw(library, account, transactionAxieList)
      .then(response => {
        console.log("success withdraw()")
        setShowSuccessTransaction(true)
        setSuccessTransactionUrl(
          getTransactionUrl(response.hash, response.networkId)
        )
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        exitModalCallback()
      })
  }

  //TODO-API: add call here to update posted axies
  const updatedPosted = updateAxiesMap => {
    update(library, account, transactionAxieList, updateAxiesMap)
      .then(response => {
        console.log("success update()")
        setShowSuccessTransaction(true)
        setSuccessTransactionUrl(
          getTransactionUrl(response.hash, response.networkId)
        )
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        exitModalCallback()
      })
  }

  //TODO-API: add call here to update posted axies
  const updatedLendable = updateAxiesMap => {
    lend(library, account, transactionAxieList, updateAxiesMap)
      .then(response => {
        console.log("sucess update()")
        setShowSuccessTransaction(true)
        setSuccessTransactionUrl(
          getTransactionUrl(response.hash, response.networkId)
        )
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        exitModalCallback()
      })
  }

  const removePosted = (withdrawList = transactionAxieList) => {
    withdraw(library, account, withdrawList)
      .then(response => {
        console.log("success withdraw()")
        setShowSuccessTransaction(true)
        setSuccessTransactionUrl(
          getTransactionUrl(response.hash, response.networkId)
        )
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        exitRemoveModalCallback()
      })
  }

  const removeSinglePosted = d => {
    console.log("withdraw single axie")
    let tmp = [d]
    removePosted(tmp)
  }

  const renderRemoveModal = () => {
    let modalTitle = `Edit ${transactionAxieList.length} Axies`
    return (
      <RemoveModal
        title={modalTitle}
        exitModalCallback={exitRemoveModalCallback}
        transactionCallback={removePosted}
      />
    )
  }

  const renderEditModal = () => {
    let modalTitle = `Edit ${transactionAxieList.length} Axies`

    switch (curTab) {
      case navChoices.LENDING:
        return (
          <ExpireModal
            title={modalTitle}
            exitModalCallback={exitModalCallback}
            transactionCallback={updatedExpirationCallback}
          />
        )
      case navChoices.POSTED:
        return (
          <EditModal
            title={modalTitle}
            exitModalCallback={exitModalCallback}
            data={transactionAxieList}
            transactionCallback={updatedPosted}
            buttonMsg="Update Axies"
          />
        )
      case navChoices.LENDABLE:
        return (
          <EditModal
            title={modalTitle}
            exitModalCallback={exitModalCallback}
            data={transactionAxieList}
            transactionCallback={updatedLendable}
            buttonMsg="Post Axies to marketplace"
          />
        )
    }
  }

  const handleApprove = () => {
    hasLenderAccess(account).then(response => {
      if (response == true) {
        return approve(library, account)
          .then(response => {
            setShowSuccessTransaction(true)
            setSuccessTransactionUrl(
              getTransactionUrl(response.hash, response.networkId)
            )
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        alert("Sorry you're not a part of our alpha! Please check back later!")
      }
    })
  }

  if (!isApproved) {
    return (
      <>
        <div className="flex flex-col my-auto mx-auto">
          <h2 className="text-axietree-accent">
            Please approve the AxieTree smart contract in order to lend your
            Axies
          </h2>
          <button
            className="bg-axietree-accent text-background py-3 px-8 mx-auto rounded-lg my-2"
            onClick={() => handleApprove()}
          >
            <h3>Approve</h3>
          </button>
        </div>
        {showSuccessTransaction && (
          <div className="absolute top-5 right-5">
            <ConfirmTransactionModal
              link={successTransactionUrl}
              closeModalCallback={successTransactionCloseCallback}
            />
          </div>
        )}
      </>
    )
  }

  if (account && lendingData && postedData && lendableData) {
    return (
      <div className="flex flex-row flex-grow">
        <div
          className={
            (displayFilter ? "border-r border-card-stroke" : "") +
            " flex flex-col"
          }
        >
          <div
            className={(displayFilter ? "" : "invisible") + " flex-grow h-0"}
          >
            <Filter
              filterCallback={filterCallback}
              toggleFilterCallback={closeFilter}
            />
          </div>
        </div>
        <div className="flex flex-col flex-grow">
          {showEditModal && renderEditModal()}
          {showRemoveModal && renderRemoveModal()}
          <LendNav
            curTab={curTab}
            lendNavCallback={lendNavCallback}
            totalLending={lendingData.length}
            totalPosted={postedData.length}
            totalLendable={lendableData.length}
            submitLendListCallback={submitLendListCallback}
            submitLendAllCallback={submitLendAllCallback}
            lendListLength={selectedLendableAxiesLength}
            submitEditListCallback={submitEditListCallback}
            submitEditAllCallback={submitEditAllCallback}
            editListLength={selectedEditLength}
            submitRemoveAllCallback={submitRemoveAllCallback}
            submitRemoveSelectedCallback={submitRemoveSelectedCallback}
          />
          <div className="my-16">
            <button
              className="md:hidden ml-24 mb-8 px-16 py-10 bg-background border border-axietree-accent rounded-md text-axietree-accent"
              onClick={() => setDisplayFilter(true)}
            >
              <p>Filter (0)</p>
            </button>
          </div>
          <div
            onScroll={handleScrollEnd}
            className="flex-grow h-0 md:overflow-y-auto axie-grid grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 xxxl:grid-cols-5"
          >
            {/* Currently LENDING axies */}
            {curTab === navChoices.LENDING &&
              displayListLending.map(d => {
                let index = selectedLendingAxies.indexOf(d)
                let isChecked = false
                let { wanted } = getMarketData(d, lendingData)
                if (index > -1) isChecked = true

                if (d.stage < 3) {
                  return
                }

                return (
                  <div
                    className={
                      (isFilterDisplay(filter, d, lendingData)
                        ? ""
                        : "hidden") + " grid-card"
                    }
                    key={d.id + "r"}
                  >
                    <Card
                      isChecked={isChecked}
                      axieSelectCallback={handleSelected}
                      data={d}
                      marketData={getMarketData(d, lendingData)}
                      key={d.id + "r-card"}
                      earnings={
                        d.id in pendingSlp ? pendingSlp[d.id] : "loading..."
                      }
                    >
                      <div className="lending-selection">
                        <p>
                          When loan expires return to:{" "}
                          {wanted ? "Inventory" : "Marketplace"}
                        </p>
                      </div>
                    </Card>
                  </div>
                )
              })}
            {/* Currently POSTED axies */}
            {curTab === navChoices.POSTED &&
              displayListPosted.map(d => {
                let index = selectedPostedAxies.indexOf(d)
                let isChecked = false
                if (index > -1) isChecked = true

                if (d.stage < 3) {
                  return
                }

                return (
                  <div
                    className={
                      (isFilterDisplay(filter, d, postedData) ? "" : "hidden") +
                      " grid-card"
                    }
                    key={d.id + "r"}
                  >
                    <Card
                      isChecked={isChecked}
                      axieSelectCallback={handleSelected}
                      data={d}
                      marketData={getMarketData(d, postedData)}
                      key={d.id + "r-card"}
                    >
                      <button
                        onClick={() => removeSinglePosted(d)}
                        className="posting-selection"
                      >
                        <h3>Remove from marketplace</h3>
                      </button>
                    </Card>
                  </div>
                )
              })}
            {/* Currently LENDABLE axies */}
            {curTab === navChoices.LENDABLE &&
              displayListLendable.map(d => {
                let index = selectedLendableAxies.indexOf(d)
                let isChecked = false
                if (index > -1) isChecked = true

                if (d.stage < 3) {
                  return
                }

                return (
                  <div
                    className={
                      (isFilterDisplay(filter, d, lendableData)
                        ? ""
                        : "hidden") + " grid-card"
                    }
                    key={d.id + "r"}
                  >
                    <Card
                      isChecked={isChecked}
                      axieSelectCallback={handleSelected}
                      data={d}
                      marketData={getMarketData(d, lendableData)}
                      key={d.id + "r-card"}
                    ></Card>
                  </div>
                )
              })}
          </div>
          {showSuccessTransaction && (
            <div className="absolute top-5 right-5">
              <ConfirmTransactionModal
                link={successTransactionUrl}
                closeModalCallback={successTransactionCloseCallback}
              />
            </div>
          )}
        </div>
      </div>
    )
  } else {
    // TODO: add loading spinner?
    return (
      <div className="mx-auto my-auto items-center">
        <h1 className="text-axietree-accent">Fetching...</h1>
        <div className="mx-auto">
          <Loading />
        </div>
      </div>
    )
  }
}

export default Lend
