import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

//Web3React Provider
import { useWeb3React } from "@web3-react/core"

import { getAccountStatus, AccountStatus } from "../../services/axieTreeApi"

import Account from "../account/account"

export const navChoices = {
  BORROWABLE: 0,
  BORROWING: 1,
}

const BorrowNav = ({
  curTab,
  borrowNavCallback,
  totalBorrowable,
  totalReturnable,
  borrowSelectedLength,
  returnSelectedLength,
  submitBorrowListCallback,
  submitReturnListCallback,
}) => {
  const context = useWeb3React()
  const { library, account } = context
  const [accountStatus, setAccountStatus] = useState()

  const [showAccountModal, setShowAccountModal] = useState(false)
  const openAccountModal = () => {
    setShowAccountModal(true)
  }

  const closeAccountModal = () => {
    setShowAccountModal(false)
  }

  useEffect(() => {
    if (account) {
      getAccountStatus(account).then(response => {
        setAccountStatus(response)
      })
    }
  }, [account])

  const [currentNav, setCurrentNav] = useState(curTab)

  const handleNav = choice => {
    if (choice === currentNav) {
      return //No need to do anything
    }
    switch (choice) {
      case navChoices.BORROWABLE:
        setCurrentNav(navChoices.BORROWABLE)
        borrowNavCallback(navChoices.BORROWABLE)
        break
      case navChoices.BORROWING:
        setCurrentNav(navChoices.BORROWING)
        borrowNavCallback(navChoices.BORROWING)
        break
    }
  }

  return (
    <>
      <div className="lend-nav mx-auto md:m-24">
        <button
          className={currentNav === navChoices.BORROWABLE ? "selected" : ""}
          onClick={() => handleNav(navChoices.BORROWABLE)}
        >
          Borrowable
          <p className="ml-1">({totalBorrowable})</p>
        </button>
        <button
          className={
            (currentNav === navChoices.BORROWING ? "selected" : "") +
            " flex-grow"
          }
          onClick={() => handleNav(navChoices.BORROWING)}
        >
          Borrowing
          {/* TODO: add another param */}
          <p className="ml-1">({totalReturnable})</p>
        </button>
        {accountStatus === AccountStatus.REGISTERED ? (
          <div className="actions w-full left-0 bottom-0 bg-background fixed inline md:block md:static px-16 py-12 md:p-0">
            {currentNav === navChoices.BORROWABLE ? (
              <button
                className={
                  (borrowSelectedLength === 0 ? "opacity-80" : "") +
                  " actions bg-axietree-accent text-background w-full mx-auto md:w-auto md:float-right"
                }
                onClick={() => submitBorrowListCallback()}
                disabled={borrowSelectedLength === 0}
              >
                <p className="mx-auto">
                  Borrow Selected ({borrowSelectedLength})
                </p>
              </button>
            ) : (
              <button
                disabled={returnSelectedLength === 0}
                className={
                  (returnSelectedLength === 0 ? "opacity-80" : "") +
                  " actions bg-axietree-accent text-background w-full mx-auto md:w-auto md:float-right"
                }
                onClick={submitReturnListCallback}
              >
                <p className="mx-auto">
                  Edit Epoch Settings ({returnSelectedLength})
                </p>
              </button>
            )}
          </div>
        ) : (
          <div className="actions">
            <button
              className="actions bg-axietree-accent text-background"
              onClick={openAccountModal}
            >
              <p>Register to Borrow</p>
            </button>
          </div>
        )}
      </div>
      {showAccountModal && (
        <div
          onClick={closeAccountModal}
          className="absolute flex items-center top-0 right-0 w-screen h-screen bg-background bg-opacity-80 overscroll-contain z-50"
        >
          <div
            onClick={e => {
              //stop clicks getting to the overlay
              e.stopPropagation()
            }}
            className="mx-auto"
          >
            <Account closeModalCallback={closeAccountModal} />
          </div>
        </div>
      )}
    </>
  )
}

export default BorrowNav
