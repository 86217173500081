import { createWatcher } from "@makerdao/multicall"
import { ethers } from "ethers"
const { parseEther, formatEther } = ethers.utils
const BN = ethers.BigNumber.from
// const https = require("https")

const {
  AXIE_ABI,
  AXIE_ADDRESS,
  BORROWER_ADDRESS,
  BORROWER_CONTROLLER_ABI,
  BORROWER_CONTROLLER_ADDRESS,
  LEASE_ABI,
  LEASE_ADDRESS,
  LENDER_ADDRESS,
  LENDER_CONTROLLER_ABI,
  LENDER_CONTROLLER_ADDRESS,
  MARKETPLACE_ADDRESS,
  CONFIG,
  SLP_ADDRESS,
} = require("./constants.js")

// const pendingSlp = address => {
//   return new Promise((resolve, reject) => {
//     https.get(
//       `https://lunacia.skymavis.com/game-api/clients/${address}/items/1`,
//       response => {
//         let data = ""
//         response.on("data", chunk => {
//           data += chunk
//         })
//         response.on("end", () => {
//           let result = JSON.parse(data)
//           console.log(result)
//           resolve(result)
//         })
//       }
//     )
//   })
// }

const getLeaseCalls = (prefix, id) => {
  return [
    {
      target: LEASE_ADDRESS,
      call: ["borrower(uint256)(address)", id],
      returns: [[`lease-borrower-${prefix}-${id}`]],
    },
    {
      target: LEASE_ADDRESS,
      call: ["ethFee(uint256)(uint256)", id],
      returns: [[`lease-eth-${prefix}-${id}`]],
    },
    {
      target: LEASE_ADDRESS,
      call: ["slpPercent(uint256)(uint256)", id],
      returns: [[`lease-slp-${prefix}-${id}`]],
    },
    {
      target: LEASE_ADDRESS,
      call: ["duration(uint256)(uint256)", id],
      returns: [[`lease-duration-${prefix}-${id}`]],
    },
    {
      target: LEASE_ADDRESS,
      call: ["wanted(uint256)(bool)", id],
      returns: [[`lease-wanted-${prefix}-${id}`]],
    },
    {
      target: LEASE_ADDRESS,
      call: ["repaid(uint256)(bool)", id],
      returns: [[`lease-returned-${prefix}-${id}`]],
    },
    // {
    //   target: LEASE_ADDRESS,
    //   call: ["start(uint256)(uint256)", id],
    //   returns: [[`lease-start-${prefix}-${id}`]],
    // },
    {
      target: LEASE_ADDRESS,
      call: ["rewards(uint256)(uint256)", id],
      returns: [[`lease-rewards-${prefix}-${id}`]],
    },
  ]
}

// Returns true if the lender already approved the contract
export const approved = async (provider, account) => {
  const nft = new ethers.Contract(AXIE_ADDRESS, AXIE_ABI, provider.getSigner())
  return nft.isApprovedForAll(account, LENDER_CONTROLLER_ADDRESS)
}

// Requests the lender to approve the contract
export const approve = async (provider, account) => {
  const nft = new ethers.Contract(AXIE_ADDRESS, AXIE_ABI, provider.getSigner())
  return nft.setApprovalForAll(LENDER_CONTROLLER_ADDRESS, true)
}

export const fetchBorrowerAxies = async (
  provider,
  account,
  onBorrowable,
  onBorrowed
) => {
  const watcher = createWatcher(
    [
      {
        target: AXIE_ADDRESS,
        call: ["balanceOf(address)(uint256)", MARKETPLACE_ADDRESS],
        returns: [["MARKETPLACE_BALANCE"]],
      },
      {
        target: BORROWER_ADDRESS,
        call: ["custodians(address)(address)", account],
        returns: [["CUSTODIAN_ADDRESS"]],
      },
    ],
    CONFIG
  )
  watcher.batch().subscribe(async updates => {
    let newCalls = []
    let borrowable = {}
    let borrowed = {}
    for (let update of updates) {
      if (update.type === "MARKETPLACE_BALANCE") {
        let balance = update.value
        if (balance == 0) {
          onBorrowable([])
          continue
        }
        for (let i = 0; i < balance; i++) {
          newCalls.push({
            target: AXIE_ADDRESS,
            call: [
              "tokenOfOwnerByIndex(address,uint256)(uint256)",
              MARKETPLACE_ADDRESS,
              i,
            ],
            returns: [[`borrowable-${i}`]],
          })
        }
      } else if (update.type.startsWith("borrowable-")) {
        newCalls = newCalls.concat(getLeaseCalls("borrowable", update.value))
      } else if (update.type === "CUSTODIAN_ADDRESS") {
        let custodian = update.value
        if (custodian == "0x0000000000000000000000000000000000000000") {
          onBorrowed([])
          continue
        }
        newCalls.push({
          target: AXIE_ADDRESS,
          call: ["balanceOf(address)(uint256)", custodian],
          returns: [[`CUSTODIAN_BALANCE-${custodian}`]],
        })
      } else if (update.type.startsWith("CUSTODIAN_BALANCE-")) {
        let balance = update.value
        if (balance == 0) {
          onBorrowed([])
          continue
        }
        let custodian = update.type.substring(18, update.type.length)
        for (let i = 0; i < balance; i++) {
          newCalls.push({
            target: AXIE_ADDRESS,
            call: [
              "tokenOfOwnerByIndex(address,uint256)(uint256)",
              custodian,
              i,
            ],
            returns: [[`borrowed-${i}`]],
          })
        }
      } else if (update.type.startsWith("borrowed-")) {
        newCalls = newCalls.concat(getLeaseCalls("borrowed", update.value))
      } else if (update.type.startsWith("lease-")) {
        let [_, param, prefix, id] = update.type.split("-")
        let lease
        if (prefix === "borrowed") {
          if (!(id in borrowed)) {
            borrowed[id] = { id: id }
          }
          lease = borrowed[id]
        } else {
          if (!(id in borrowable)) {
            borrowable[id] = { id: id }
          }
          lease = borrowable[id]
        }
        if (param === "borrower") {
          lease["slp"] = lease["slp"] | 0 // + (await pendingSlp(update.value))
        }
        if (param === "eth") {
          lease[param] = formatEther(update.value)
        } else {
          lease[param] = update.value
        }
      }
    }
    if (newCalls.length > 0) {
      watcher.tap(calls => newCalls)
    }
    if (Object.keys(borrowable).length > 0) {
      onBorrowable(Object.values(borrowable))
    }
    if (Object.keys(borrowed).length > 0) {
      onBorrowed(Object.values(borrowed))
    }
  })
  watcher.start()
}

export const fetchLenderAxies = async (
  provider,
  account,
  onLending,
  onPosted,
  onLendable
) => {
  let fetchedLentOrPosted = false
  const watcher = createWatcher(
    [
      {
        target: AXIE_ADDRESS,
        call: ["balanceOf(address)(uint256)", account],
        returns: [["LENDABLE_AXIES"]],
      },
      {
        target: LENDER_ADDRESS,
        call: ["count(address)(uint256)", account],
        returns: [["LENT_COUNT"]],
      },
    ],
    CONFIG
  )
  watcher.batch().subscribe(updates => {
    let newCalls = []
    let lendable = []
    let lentOrPosted = []
    let lent = {}
    let posted = {}
    let isBorrowed = {}
    for (let update of updates) {
      if (update.type === "LENDABLE_AXIES") {
        let balance = update.value
        if (balance == 0) {
          onLendable([])
          continue
        }
        for (let i = 0; i < balance; i++) {
          newCalls.push({
            target: AXIE_ADDRESS,
            call: ["tokenOfOwnerByIndex(address,uint256)(uint256)", account, i],
            returns: [[`lendable-${i}`]],
          })
        }
      } else if (update.type.startsWith("lendable-")) {
        lendable.push(update.value)
      } else if (update.type === "LENT_COUNT") {
        let count = update.value
        if (count == 0) {
          onLending([])
          onPosted([])
          continue
        }
        for (let i = 0; i < count; i++) {
          newCalls.push({
            target: LENDER_ADDRESS,
            call: ["lenders(address,uint256)(uint256)", account, i],
            returns: [[`lent-${i}`]],
          })
        }
      } else if (update.type.startsWith("lent-")) {
        let axie = update.value
        newCalls.push({
          target: LEASE_ADDRESS,
          call: ["lender(uint256)(address payable)", axie],
          returns: [[`lender-${axie}`]],
        })
        newCalls.push({
          target: LEASE_ADDRESS,
          call: ["borrower(uint256)(address)", axie],
          returns: [[`borrower-${axie}`]],
        })
      } else if (update.type.startsWith("lender-")) {
        fetchedLentOrPosted = true
        if (update.value === account) {
          let axie = update.type.substring(7, update.type.length)
          lentOrPosted.push(axie)
        }
      } else if (update.type.startsWith("borrower-")) {
        let axie = update.type.substring(9, update.type.length)
        let borrower = update.value
        isBorrowed[axie] =
          borrower != "0x0000000000000000000000000000000000000000"
      } else if (update.type.startsWith("lease-")) {
        let [_, param, prefix, id] = update.type.split("-")
        let lease
        if (prefix === "lent") {
          if (!(id in lent)) {
            lent[id] = { id: id }
          }
          lease = lent[id]
        } else {
          if (!(id in posted)) {
            posted[id] = { id: id }
          }
          lease = posted[id]
        }
        if (param === "eth") {
          lease[param] = formatEther(update.value)
        } else {
          lease[param] = update.value
        }
      }
    }
    if (lendable.length > 0) {
      onLendable(lendable.map(id => ({ id: id })))
    }
    if (lentOrPosted.length > 0) {
      for (let axie of lentOrPosted) {
        if (!isBorrowed[axie]) {
          newCalls = newCalls.concat(getLeaseCalls("posted", axie))
        } else {
          newCalls = newCalls.concat(getLeaseCalls("lent", axie))
        }
      }
    }
    if (fetchedLentOrPosted) {
      onLending(Object.values(lent))
      onPosted(Object.values(posted))
    }
    if (newCalls.length > 0) {
      watcher.tap(calls => newCalls)
    }
  })
  watcher.start()
}

//axies = list of axies ids
export const borrow = async (provider, account, axies) => {
  const signer = provider.getSigner()
  const bctrl = new ethers.Contract(
    BORROWER_CONTROLLER_ADDRESS,
    BORROWER_CONTROLLER_ABI,
    signer
  )
  const lease = new ethers.Contract(LEASE_ADDRESS, LEASE_ABI, signer)
  let ids = axies.map(axie => axie.id || axie)
  let fee = BN(0)
  for (let id of ids) {
    fee = fee.add(await lease.ethFee(BN(id)))
  }
  let balance = await provider.getBalance(account)
  if (fee.gt(balance)) {
    throw new Error("Insufficient balance.")
  }
  return bctrl.borrow(ids, { value: fee })
}

export const payback = async (provider, account, axies) => {
  const signer = provider.getSigner()
  const bctrl = new ethers.Contract(
    BORROWER_CONTROLLER_ADDRESS,
    BORROWER_CONTROLLER_ABI,
    signer
  )
  let ids = [...axies.map(axie => axie.id || axie), 0, 0, 0].slice(0, 3)
  return bctrl.payback(ids, [true, true, true])
}

//axies = list of axies objects in this transactions
//updateMap = key=axid_id value=Map() of lend fields
export const lend = async (provider, account, axies, updateMap) => {
  const signer = provider.getSigner()
  const lctrl = new ethers.Contract(
    LENDER_CONTROLLER_ADDRESS,
    LENDER_CONTROLLER_ABI,
    signer
  )
  let ids = []
  let ethFees = []
  let slpPercent = []
  let duration = []
  let retrieve = []
  for (const [axie, param] of updateMap.entries()) {
    ids.push(axie)
    ethFees.push(parseEther(param.eth.toString()))
    slpPercent.push(param.slp)
    duration.push(param.duration)
    retrieve.push(false)
  }
  return lctrl.lend(ids, ethFees, slpPercent, duration, retrieve)
}

//axies = list of axies objects in this transactions
export const withdraw = async (provider, account, axies) => {
  const signer = provider.getSigner()
  const lctrl = new ethers.Contract(
    LENDER_CONTROLLER_ADDRESS,
    LENDER_CONTROLLER_ABI,
    signer
  )
  let ids = axies.map(axie => axie.id || axie)
  return lctrl.withdraw(ids)
}

//axies = list of axies objects in this transactions
//updateMap = key=axid_id value=Map() of lend fields
export const update = async (provider, account, axies, updateMap) => {
  const signer = provider.getSigner()
  const lctrl = new ethers.Contract(
    LENDER_CONTROLLER_ADDRESS,
    LENDER_CONTROLLER_ABI,
    signer
  )
  let ids = []
  let ethFees = []
  let slpPercent = []
  let duration = []
  let retrieve = []
  for (const [axie, param] of updateMap.entries()) {
    ids.push(axie)
    ethFees.push(parseEther(param.eth.toString()))
    slpPercent.push(param.slp)
    duration.push(param.duration)
    retrieve.push(false)
  }
  return lctrl.update(ids, ethFees, slpPercent, duration, retrieve)
}

// axies = list of axie ids to return
// returned = list of booleans, should be true if user wants to return
// corresponding axie
export const remove = async (provider, account, axies, returned) => {
  const signer = provider.getSigner()
  const bctrl = new ethers.Contract(
    BORROWER_CONTROLLER_ADDRESS,
    BORROWER_CONTROLLER_ABI,
    signer
  )
  return bctrl.payback(
    [...axies.map(axie => axie.id || axie), 0, 0, 0].slice(0, 3),
    [...returned, false, false, false].slice(0, 3)
  )
}

// returns the addresses of the custodians for each axie
export const custodians = (provider, account, axies) => {
  return new Promise((resolve, reject) => {
    const signer = provider.getSigner()
    const calls = []
    for (let axie of axies) {
      calls.push({
        target: LEASE_ADDRESS,
        call: ["borrower(uint256)(address)", axie],
        returns: [[`${axie}`]],
      })
    }
    const watcher = createWatcher(calls, CONFIG)
    watcher.batch().subscribe(updates => {
      let map = {}
      for (let update of updates) {
        map[update.type] = update.value
      }
      let cs = []
      for (let axie of axies) {
        cs.push(map[`${axie}`])
      }
      resolve(cs)
    })
    watcher.start()
  })
}
