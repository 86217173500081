// Frameworks
import React, { useEffect, useState } from "react"

//API
import { getPlayerAxies } from "../services/getAxie"
import {
  AccountStatus,
  getEarnings,
  getAccountStatus,
} from "../services/axieTreeApi"
//Components
import Filter from "../components/filter/filter"
import Borrow from "../components/borrow/borrow"
import MainNav from "../components/mainNav/mainNav"
import Sort from "../components/mainNav/sort"
//TODO: remove if no longer used

import Lend from "../components/lend/lend"

//Web3React Provider
import { useWeb3React } from "@web3-react/core"
import Registration from "../components/registration/registration"

// Main Route
function Main() {
  const context = useWeb3React()
  const { account } = context

  const [borrowTab, setBorrowTab] = useState(true)
  const [showFilter, setShowFilter] = useState(false)
  const [accountStatus, setAccountStatus] = useState()

  useEffect(() => {
    if (account) {
      getAccountStatus(account).then(response => {
        setAccountStatus(response)
      })
    }
  }, [account])

  const handleChangeTabCallback = () => {
    setBorrowTab(!borrowTab)
  }

  const toggleFilterCallback = () => {
    setShowFilter(!showFilter)
  }

  const registrationCallback = () => {
    // setShowAxies(true)
  }

  return (
    <div className="flex flex-col flex-grow">
      {/* {account &&
        accountStatus &&
        accountStatus !== AccountStatus.UNREGISTERED && (
          <MainNav handleChangeTabCallback={handleChangeTabCallback} />
        )} */}
      <MainNav handleChangeTabCallback={handleChangeTabCallback} />
      <div className="flex flex-row flex-grow sm:border-t sm:border-card-stroke">
        {/* <div className={showFilter ? "block sm:block" : "hidden sm:block"}>
              <Filter toggleFilterCallback={toggleFilterCallback} />
            </div> */}
        {/* TODO: clean up this mess */}
        {/* {account &&
          accountStatus &&
          accountStatus !== AccountStatus.REGISTERED && (
            <Registration
              status={accountStatus}
              registrationCallback={registrationCallback}
            />
          )} */}
        {/* Borrow Tab */}
        {borrowTab && (
          <div className="flex flex-col w-full pb-3">
            <Borrow />
          </div>
        )}
        {/* Lend Tab */}
        {!borrowTab && (
          <div className="flex flex-col w-full pb-3">
            <Lend />
          </div>
        )}
      </div>
    </div>
  )
}

export default Main
