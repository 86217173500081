import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { observer } from "mobx-react-lite"

//Web3React Provider
import { useWeb3React } from "@web3-react/core"

const PrivateRoute = observer(props => {
  const context = useWeb3React()
  const { account } = context
  const { component: Component, location, ...rest } = props

  useEffect(() => {
    if (!account && location.pathname !== `/app/login`) {
      // If the user is not logged in, redirect to the login page.
      navigate(`/app/login`)
    }
  }, [account, location])
  return account ? (
    <div className="flex flex-col flex-grow">
      <Component {...rest} />
    </div>
  ) : null
})

export default PrivateRoute
