module.exports = {
  LENDER_CONTROLLER_ABI: require("./abi/LenderController.sol/LenderController.json")
    .abi,
  BORROWER_CONTROLLER_ABI: require("./abi/BorrowerController.sol/BorrowerController.json")
    .abi,
  LEASE_ABI: require("./abi/state/Lease.sol/Lease.json").abi,
  AXIE_ABI: require("./abi/mocks/AxieNFT.sol/AxieNFT.json").abi,
  AXIE_ADDRESS: `${process.env.AXIE_ADDRESS}`,
  LENDER_CONTROLLER_ADDRESS: `${process.env.LENDER_CONTROLLER_ADDRESS}`,
  BORROWER_CONTROLLER_ADDRESS: `${process.env.BORROWER_CONTROLLER_ADDRESS}`,
  SLP_ADDRESS: `${process.env.SLP_ADDRESS}`,
  MARKETPLACE_ADDRESS: `${process.env.MARKETPLACE_ADDRESS}`,
  BORROWER_ADDRESS: `${process.env.BORROWER_ADDRESS}`,
  LENDER_ADDRESS: `${process.env.LENDER_ADDRESS}`,
  LEASE_ADDRESS: `${process.env.LEASE_ADDRESS}`,
  CONFIG: {
    // rpcUrl: "wss://rinkeby.infura.io/ws/v3/6f44359164be48c0b4b26fae19a63a30",
    // multicallAddress: "0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821",
    // interval: 10000000,

    rpcUrl: `${process.env.RPC_URL}`,
    multicallAddress: `${process.env.MULTICALL_ADDRESS}`,
    interval: 10000000,
  },
}
