import React from "react"
import AxiaCircleLogo from "./logos/axiaCircleLogo"

const Loading = () => {
  return (
    <div className="">
      <AxiaCircleLogo spinning={true} />
    </div>
  )
}

export default Loading
