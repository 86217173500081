import React, { useEffect, useState } from "react"

const Modal = ({ children, title, exitModalCallback }) => {
  return (
    <div
      className={
        "block fixed top-0 left-0 z-0 w-full h-full bg-background bg-opacity-80"
      }
    >
      <div
        onClick={() => exitModalCallback()}
        className="relative w-full h-full z-10 flex flex-col"
      >
        <div
          onClick={e => {
            //stop clicks getting to the overlay
            e.stopPropagation()
          }}
          className="relative edit-modal z-20 flex flex-col bg-opacity-100 bg-card-bg m-auto align-middle"
        >
          <div className="flex flex-row justify-between">
            <h2 className="text-white">{title}</h2>
            <button onClick={() => exitModalCallback()}>
              <svg
                className="fill-current text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  className="heroicon-ui"
                  d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
                />
              </svg>
            </button>
          </div>
          <div className="edit-heading-border border-t border-card-stroke">
            <div className="edit-content flex flex-col">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
