import React, { useState, useEffect } from "react"

export const navChoices = {
  LENDING: 0,
  POSTED: 1,
  LENDABLE: 2,
}

const LendNav = ({
  curTab,
  lendNavCallback,
  totalLending,
  totalPosted,
  totalLendable,
  submitLendListCallback,
  submitLendAllCallback,
  lendListLength,
  submitEditListCallback,
  submitEditAllCallback,
  editListLength,
  submitRemoveAllCallback,
  submitRemoveSelectedCallback,
}) => {
  const [currentNav, setCurrentNav] = useState(curTab)

  const handleNav = choice => {
    if (choice === currentNav) {
      return //No need to do anything
    }
    switch (choice) {
      case navChoices.LENDING:
        setCurrentNav(navChoices.LENDING)
        lendNavCallback(navChoices.LENDING)
        break
      case navChoices.POSTED:
        setCurrentNav(navChoices.POSTED)
        lendNavCallback(navChoices.POSTED)
        break
      case navChoices.LENDABLE:
        setCurrentNav(navChoices.LENDABLE)
        lendNavCallback(navChoices.LENDABLE)
        break
    }
  }

  return (
    <div className="lend-nav mx-auto md:m-24">
      <button
        className={currentNav === navChoices.LENDING ? "selected" : ""}
        onClick={() => handleNav(navChoices.LENDING)}
      >
        Lending
        <p className="ml-1">({totalLending})</p>
      </button>
      <button
        className={currentNav === navChoices.POSTED ? "selected" : ""}
        onClick={() => handleNav(navChoices.POSTED)}
      >
        Posted<p className="ml-1">({totalPosted})</p>
      </button>
      <button
        className={
          (currentNav === navChoices.LENDABLE ? "selected" : "") + " flex-grow"
        }
        onClick={() => handleNav(navChoices.LENDABLE)}
      >
        Lendable
        <p className="ml-1">({totalLendable})</p>
      </button>
      <div className="actions w-full left-0 bottom-0 bg-background fixed inline md:block md:static px-16 py-12 md:p-0">
        {currentNav === navChoices.LENDABLE && (
          <>
            <button
              className={
                (editListLength === 0 ? "opacity-80" : "") +
                " actions bg-axietree-accent text-background w-full mx-auto md:w-auto md:float-right"
              }
              onClick={() => submitLendListCallback()}
              disabled={editListLength === 0}
            >
              <p className="mx-auto">
                Edit and Lend selected ({lendListLength})
              </p>
            </button>
            {/* <button
              className="actions bg-card-bg text-axietree-accent"
              onClick={() => submitLendAllCallback()}
            >
              <p>Lend All</p>
            </button> */}
          </>
        )}

        {currentNav === navChoices.POSTED && (
          <button
            className="actions bg-axietree-accent text-background w-full mx-auto md:w-auto md:float-right"
            onClick={() => submitRemoveSelectedCallback()}
            disabled={editListLength === 0}
          >
            <p className="mx-auto">Remove Selected ({editListLength})</p>
          </button>
        )}
        {/* {currentNav === navChoices.POSTED && (
          <button
            className="actions bg-card-bg text-axietree-accent"
            onClick={() => submitRemoveAllCallback()}
          >
            <p>Remove All</p>
          </button>
        )} */}
        {currentNav !== navChoices.LENDABLE && (
          <button
            className="actions bg-axietree-accent text-background w-full mx-auto md:w-auto md:float-right"
            onClick={() => submitEditListCallback()}
            disabled={editListLength === 0}
          >
            <p className="mx-auto">Edit Selected ({editListLength})</p>
          </button>
        )}
        {/* <button
          className="actions bg-card-bg text-axietree-accent"
          onClick={() => submitEditAllCallback()}
        >
          <p>Edit All</p>
        </button> */}
      </div>
    </div>
  )
}

export default LendNav
